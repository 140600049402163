/* eslint-disable react/jsx-newline */
// eslint-disable-next-line no-restricted-imports
import { Box, Collapse, Flex, Link, Text, useDisclosure } from '@chakra-ui/react';

import Disclosure from './Disclosure';

export const CondensedSecuritiesDisclosure = ({ fiName }: { fiName: string }) => {
  return (
    <Disclosure>
      <Text>
        Investment advisory services provided by AdvisiFi, LLC (“AdvisiFi”), an SEC-registered investment adviser.
        Securities and brokerage products offered by CDS1 Securities LLC (“CDS1”) and RQD* Clearing LLC (“RQD”),
        SEC-registered broker-dealers and members FINRA/SIPC. AdvisiFi, CDS1 and RQD are not affiliates of {fiName} (the
        “Credit Union”). The Credit Union does not provide investment advisory or brokerage services, and nothing on
        this website should be considered an offer, solicitation of an offer, or advice to buy or sell securities or
        investment products or to open an investment account. Cash and securities held by RQD are (1) not insured by the
        FDIC, NCUA or other government agency, (2) not guaranteed, (3) not Credit Union deposits or obligations, and (4)
        may lose value. Brokerage services are available only to residents of the United States and in jurisdictions
        where CDS1 is registered.
      </Text>
    </Disclosure>
  );
};

export const SecuritiesDisclosure = ({ fiName }: { fiName: string }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Disclosure heading="Securities Disclosure">
      <Text>
        Investment advisory services provided by AdvisiFi, LLC (“AdvisiFi”), an investment adviser registered with the
        Securities and Exchange Commission (“SEC”). Registration does not imply a certain level of skill or training.
        AdvisiFi is a wholly-owned subsidiary of InvestiFi Inc (“InvestiFi”). AdvisiFi and InvestiFi are not affiliates
        of {fiName} (the “Credit Union”). AdvisiFi&apos;s investment advisory services are available only to residents
        of the United States in jurisdictions where AdvisiFi is registered, notice filed, or is exempt from the
        applicable registration and notice filing requirements. Information about AdvisiFi and its registration status
        is available on the{' '}
        <Link href="https://www.adviserinfo.sec.gov/" target="iapd">
          SEC&apos;s Investment Adviser Public Disclosure (IAPD)
        </Link>
        . Please refer to AdvisiFi&apos;s{' '}
        <Link href="https://investifi.co/advisifi-crs-final/" target="relationship-summary">
          Relationship Summary
        </Link>{' '}
        and{' '}
        <Link href="https://investifi.co/form-adv-part-2a-advisifi/" target="disclosure-brochure">
          Disclosure Brochure
        </Link>{' '}
        for additional information.
      </Text>

      <Text>
        Brokerage services are provided to AdvisiFi clients by CDS1 Securities LLC (“CDS1”) and RQD* Clearing LLC
        (“RQD”), SEC-registered broker-dealers and members of FINRA/SIPC. SIPC provides protection for brokerage
        customers for up to $500,00 (including up to $250,000 for uninvested cash). Brokerage services are available
        only to residents of the United States and in jurisdictions where CDS1 is registered. For more information,
        visit our disclosures page. You may check the background of these firms by visiting{' '}
        <Link href="https://brokercheck.finra.org/" target="brokercheck">
          FINRA&apos;s BrokerCheck
        </Link>
        . Cash and securities held by RQD are (1) not insured by the FDIC, NCUA or other government agency, (2) not
        guaranteed, (3) not Credit Union deposits or obligations, and (4) may lose value.{' '}
        <Link onClick={onToggle}>Continue reading...</Link>
      </Text>

      <Collapse in={isOpen}>
        <Flex gap="3" direction="column">
          <Text>
            Nothing on this website should be considered an offer, solicitation of an offer, or advice to buy or sell
            securities or investment products or to open an investment account. Past performance is no guarantee of
            future results. The rate of return on investments can vary widely over time, especially for long term
            investments. Investment losses are possible, including the potential loss of all amounts invested, including
            principal. Any historical returns, expected returns, or probability projections are hypothetical in nature
            and may not reflect actual future performance. Account holdings and other information provided are for
            illustrative purposes only and are not to be considered investment recommendations. The content on this
            website is for informational purposes only and does not constitute a comprehensive description of AdvisiFi
            or InvestiFi&apos;s investment advisory services.
          </Text>

          <Text>
            The information included on this website is based upon information reasonably available to AdvisiFi as of
            the date noted herein, or as otherwise stated in the applicable materials. Furthermore, the information
            included on the website has been obtained from sources AdvisiFi believes to be reliable; however, these
            sources cannot be guaranteed as to their accuracy or completeness. No representation, warranty or
            undertaking, express or implied, is given as to the accuracy or completeness of the information contained
            herein and no liability is accepted for the accuracy or completeness of any such information. Certain
            information contained herein constitutes “forward-looking statements”, which can be identified by the use of
            terms such as “may”, “can”, “will”, “should”, “expect”, “anticipate”, “project”, “estimate”, “intend”,
            “continue”, “target”, or “believe” (or the negatives thereof) or other variations there on or comparable
            terminology. Due to various risks and uncertainties, actual events or results or actual performance may
            differ materially from those reflected or contemplated in such forward-looking statements. No representation
            or warranty is made as to future performance or such forward-looking statements.
          </Text>

          <Text>
            By using this website, you accept and agree to InvestiFi&apos;s{' '}
            <Link href="https://investifi.co/terms-of-use/" target="investifi-terms">
              Terms of Use
            </Link>{' '}
            and{' '}
            <Link href="https://investifi.co/privacy/" target="investifi-privacy">
              Privacy Policy
            </Link>
            .
          </Text>

          <Text>
            Please visit{' '}
            <Link href="https://investifi.co/legal-disclosures" target="investifi-legal">
              https://investifi.co/legal-disclosures
            </Link>{' '}
            for applicable terms, conditions and important disclosures.
          </Text>
        </Flex>
      </Collapse>
    </Disclosure>
  );
};

export const CryptoDisclosure = ({ fiName, isCondensed }: { fiName: string; isCondensed?: boolean }) => {
  return (
    <Disclosure heading={isCondensed ? undefined : 'Cryptocurrencies Disclosure'}>
      <Box display={isCondensed ? 'block' : 'flex'} flexDirection="column" gap="4">
        <Text display={isCondensed ? 'inline' : 'block'}>
          Digital assets (cryptocurrencies) are offered by and held in a digital wallet maintained by SAFE Trust Co., a
          Wyoming trust company. SAFE is not affiliated with {fiName} (the “Credit Union”). The risk of loss in trading
          digital assets and cryptocurrencies can be substantial and you should carefully consider whether such trading
          is appropriate in your particular circumstances. The Credit Union does not offer cryptocurrency or digital
          asset trading and this is not a recommendation to open a digital asset account or an offer or sale to buy
          cryptocurrency or digital assets.{' '}
        </Text>

        <Text display={isCondensed ? 'inline' : 'block'}>
          Cryptocurrencies and digital assets are not covered by either FDIC or SIPC insurance. Legislative and
          regulatory changes or actions at the state, federal, or international level may adversely affect the use,
          transfer, exchange, and value of cryptocurrency.
        </Text>
      </Box>
    </Disclosure>
  );
};
