import { Button, Flex, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import { CfTooltip, IconBank, IconCaretRight, uiColors } from '@cryptofi/core-ui';
import { upperCase } from 'lodash';
import { Dispatch, SetStateAction } from 'react';

import { introContentsCrypto, systemOnboardingUnavailableMessage } from '~/constants';
import { OnboardingModalViews } from '~/customTypes';
import { useGetFiInfo, useGetSystemStatus } from '~/hooks';
import { isFalse } from '~/utils';

const WelcomeCryptoView = ({ setModalView }: { setModalView: Dispatch<SetStateAction<OnboardingModalViews>> }) => {
  const fiInfo = useGetFiInfo();
  const systemStatus = useGetSystemStatus();

  return (
    <Flex direction="column" gap={4} mb={6}>
      <Flex alignItems="center" gap={4}>
        <IconBank __css={{ path: { fill: 'brand.500' } }} />

        <Flex direction="column" gap={2} textAlign="left">
          <Heading as="h3" size="xs">
            {fiInfo.data?.fiName}
          </Heading>

          <Text fontSize="sm">{upperCase(fiInfo.data?.fiType)}</Text>
        </Flex>
      </Flex>

      <Heading as="h2" size="xl" color="brand.500">
        Welcome to Crypto!
      </Heading>

      <Text>Easily trade top cryptocurrencies with us, knowing your holdings are safe and secure.</Text>

      <SimpleGrid spacing={8} columns={{ base: 1, md: 3 }} marginY={10}>
        {introContentsCrypto.map((content) => {
          const { Icon, title } = content;
          return (
            <Flex w="full" alignItems="center" gap={4} key={content.title} direction={{ base: 'row', md: 'column' }}>
              <Icon boxSize={{ base: 10, md: 20 }} {...content.iconProps} />

              <Heading as="h3" size={{ base: 'sm', md: 'xs' }} color="brand.500" textAlign="center">
                {title}
              </Heading>
            </Flex>
          );
        })}
      </SimpleGrid>

      <Flex w="full" direction="column">
        <CfTooltip
          label={
            isFalse(systemStatus.data?.isCryptoAvailable) ? (
              <Text textAlign="center" maxWidth="12rem">
                {systemOnboardingUnavailableMessage}
              </Text>
            ) : null
          }
        >
          <Button
            width={{ base: 'full', md: 'auto' }}
            ml={{ md: 'auto' }}
            rightIcon={<IconCaretRight />}
            isDisabled={isFalse(systemStatus.data?.isCryptoAvailable)}
            onClick={() => {
              setModalView('termsAndConditions');
            }}
          >
            Get started
          </Button>
        </CfTooltip>

        {isFalse(systemStatus.data?.isCryptoAvailable) && (
          <Text
            display={{ base: 'block', md: 'none' }}
            width="full"
            textAlign="center"
            fontSize="sm"
            color={uiColors.sonicSilver()}
            mt="1"
          >
            {systemOnboardingUnavailableMessage}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default WelcomeCryptoView;
