import { Flex, Tooltip } from '@chakra-ui/react';
import { IconCaretRight, uiColors } from '@cryptofi/core-ui';
import { Dispatch, SetStateAction } from 'react';
import { useFormContext } from 'react-hook-form';

import { AssetLogo } from '~/components';
import { SelectedAsset, TransactionModalViews } from '~/customTypes';
import { useFeatureSetEnabled, useInvestModalContext } from '~/hooks';
import { uiConstants } from '~/theme';

import { TransactionFormValues } from './transactionSchema';

const SelectAssetButton = ({
  setModalView,
  selectedAsset,
}: {
  setModalView: Dispatch<SetStateAction<TransactionModalViews>>;
  selectedAsset: SelectedAsset | undefined;
}) => {
  const { isEnabled } = useFeatureSetEnabled();
  const { transactionType } = useInvestModalContext();
  const { resetField, clearErrors } = useFormContext<TransactionFormValues>();

  return (
    <Tooltip label="Select asset" aria-label="Select asset" hasArrow>
      <Flex
        as="button" // as button makes this element focusable
        marginY="-3" // margin and padding extend the clickable area to fill parent
        marginX="-4"
        paddingY="3"
        paddingX="4"
        alignItems="center"
        onClick={() => {
          if (isEnabled(['securities'])) {
            setModalView('searchAssets');
          } else {
            setModalView('selectAsset');
          }

          if (transactionType === 'buy') {
            resetField('buyCurrencyAmount');
            clearErrors('buyAccountId');
          }

          if (transactionType === 'sell') {
            resetField('sellCurrencyAmount');
            clearErrors('sellAccountId');
          }
        }}
        borderTopLeftRadius="lg"
        borderBottomLeftRadius="lg"
        cursor="pointer"
        _hover={{
          bg: uiConstants.hoverBackground,
        }}
        _focus={{
          bg: uiConstants.hoverBackground,
          outline: 'none',
        }}
      >
        {selectedAsset && <AssetLogo assetId={selectedAsset.id} assetType={selectedAsset?.type} showId logoSize={5} />}

        <IconCaretRight
          ml="2"
          height="1em !important" // override the default input svg height and width of "1.25em"
          width="1em !important"
          __css={{
            path: { fill: `${uiColors.sonicSilver()} !important` },
          }}
        />
      </Flex>
    </Tooltip>
  );
};

export default SelectAssetButton;
