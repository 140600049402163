import slugify from '@sindresorhus/slugify';

import { PriceIntervalMinutes, Product } from '~/customTypes';

export const uiRoutes = {
  root: () => '/',
  assetDetailsCrypto: (asset: string) => `/assets/crypto/${slugify(asset)}`,
  assetDetailsSecurities: (asset: string) => `/assets/securities/${slugify(asset, { preserveCharacters: ['.'] })}`,
  learn: () => '/learn',
  settings: () => '/settings',
  investments: () => '/investments',
  sectionDetails: (section: string) => `/learn/${slugify(section)}`,
  transactionHistory: ({ asset }: { asset?: string } = {}) => {
    if (asset) {
      return `/transaction-history?asset=${asset}`;
    }

    return '/transaction-history';
  },
  documents: () => '/documents',
  guidedInvesting: () => '/guided-investing',
};

// trim trailing slash from API URL if necessary
const apiUrl = process.env.NEXT_PUBLIC_API_URL ? process.env.NEXT_PUBLIC_API_URL.replace(/\/$/, '') : '';
const baseUrl = `${apiUrl}/v2`;

export const apiRoutes = {
  // CWS API routes
  auth: () => `${baseUrl}/dbp/auth`,
  bankAccounts: () => `${baseUrl}/user/bank-accounts`,
  buyCrypto: () => `${baseUrl}/user/buy`,
  buySecurity: () => `${baseUrl}/user/securities/buy`,
  fees: (price: number) => `${baseUrl}/user/fees?price=${price}&currency=USD`,
  fiInfo: () => `${baseUrl}/user/fi-info`,
  kyc: (product?: Product) => `${baseUrl}/user/kyc${product ? `?product=${product}` : ''}`,
  kycFields: (product?: Product) => `${baseUrl}/user/kyc/fields${product ? `?product=${product}` : ''}`,
  price: () => `${baseUrl}/token/current/price`,
  priceHistory: () => `${baseUrl}/token/historical/price`,
  report: (id: string) => `${baseUrl}/user/reports/${id}`,
  reports: () => `${baseUrl}/user/reports`,
  searchOrders: () => `${baseUrl}/user/orders/search`,
  sellCrypto: () => `${baseUrl}/user/sell`,
  sellSecurity: () => `${baseUrl}/user/securities/sell`,
  systemStatus: () => `${baseUrl}/public/status`,
  telemetryEvent: () => `${baseUrl}/user/event`,
  terms: (product?: Product) => `${baseUrl}/user/terms${product ? `?product=${product}` : ''}`,
  transactions: () => `${baseUrl}/user/transactions`,
  user: () => `${baseUrl}/user/user`,
  userAddress: () => `${baseUrl}/user/user-physical-address`,
  userComplianceSuitabilitySettings: () => `${baseUrl}/user/compliance-suitability-settings`,

  //Next.js internal API routes
  redis: {
    getSecurity: ({ ticker }: { ticker: string }) => `/api/securities/${ticker}/get`, // get one security
    getSecurityFields: ({ ticker }: { ticker: string }) => `/api/securities/${ticker}/fields`, // get specific fields of a security
    getSecurities: ({ tickers }: { tickers: string }) => `/api/securities/get?tickers=${tickers}`, // get one or many securities
    searchSecurities: ({ query }: { query: string }) => `/api/securities/search?query=${query}`, // fuzzy search securities
  },

  intrinio: {
    getSecurity: ({ ticker }: { ticker: string }) => `/api/securities/${ticker}/details`, // get general security details
    getSecurityDailyPrice: ({ ticker, interval = '15m' }: { ticker: string; interval?: PriceIntervalMinutes }) =>
      `/api/securities/${ticker}/daily-price?interval=${interval}`, // get security daily price data points
    getSecurityMetrics: ({ ticker }: { ticker: string }) => `/api/securities/${ticker}/metrics`, // get security daily metrics
    getSecuritySipPrice: ({ ticker }: { ticker: string }) => `/api/securities/${ticker}/sip`, // get security realtime price
    getNews: ({ results }: { results?: number }) =>
      results ? `/api/securities/news?results=${results}` : `/api/securities/news`, // get all company news
  },
};
