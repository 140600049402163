'use client';

import { Avatar, Box, Flex, FlexboxProps, FlexProps, Image, Text } from '@chakra-ui/react';
import {
  IconAlgo,
  IconAvax,
  IconBch,
  IconBtc,
  IconDoge,
  IconEth,
  IconLink,
  IconLtc,
  IconMkr,
  IconSol,
  IconXlm,
  IconXrp,
  uiColors,
} from '@cryptofi/core-ui';

import { tokenIdToName } from '~/constants';
import { AllAssetIds, AssetType } from '~/customTypes';
import { useGetSecurityFields } from '~/hooks';

interface Props extends FlexProps {
  assetId: AllAssetIds;
  assetType: AssetType;
  showId?: boolean;
  showName?: boolean;
  logoSize?: string | number;
  canTruncate?: boolean;
}

const AssetLogo = ({
  assetId,
  assetType,
  showId,
  showName,
  logoSize = 6,
  canTruncate = false,
  ...rest
}: Props & FlexboxProps) => {
  const imageProps = {
    maxH: 'full',
    h: 'auto',
    w: 'full',
    className: 'asset-logo', // works with classname defined in core UI to opt out of default SVG styling
  };

  const cryptoLookup = {
    AVAX: <IconAvax {...imageProps} />,
    BTC: <IconBtc {...imageProps} />,
    BCH: <IconBch {...imageProps} />,
    ETH: <IconEth {...imageProps} />,
    LTC: <IconLtc {...imageProps} />,
    XLM: <IconXlm {...imageProps} />,
    LINK: <IconLink {...imageProps} />,
    XRP: <IconXrp {...imageProps} />,
    ALGO: <IconAlgo {...imageProps} />,
    MKR: <IconMkr {...imageProps} />,
    DOGE: <IconDoge {...imageProps} />,
    SOL: <IconSol {...imageProps} />,
  };

  // Use explicit assetType if provided, otherwise fall back to isCrypto check
  const isCryptoAsset = assetType === 'crypto';

  const security = useGetSecurityFields({
    ticker: assetId,
    enabled: !isCryptoAsset,
    fields: ['name', 'image'],
  });

  const assetName = isCryptoAsset ? (tokenIdToName as any)[assetId] : security.data?.name;

  const getLogoImage = () => {
    if (isCryptoAsset && cryptoLookup[assetId as keyof typeof cryptoLookup]) {
      return cryptoLookup[assetId as keyof typeof cryptoLookup];
    }

    if (security.data?.image?.url) {
      return <Image src={security.data.image.url} alt={`${assetId} logo`} {...imageProps} />;
    }

    return (
      <Avatar name={assetId} bgColor={uiColors.coolElegance()} color="white" width="full" height="full" icon={<></>} /> //icon={<></>} prevents the default user icon from showing
    );
  };

  return (
    <Flex alignItems="center" lineHeight={5} {...rest}>
      <Box
        p={security.data?.image?.isLightImage ? `${logoSize}px` : 0} //padding should be 1/4th of the logosize when a background is applied
        bg={security.data?.image?.isLightImage ? uiColors.black() : 'transparent'}
        h={logoSize}
        w={logoSize}
        flexShrink="0"
      >
        {getLogoImage()}
      </Box>

      {(showId || showName) && (
        <Flex flexDir="column" ml="2">
          {showId && (
            <Text fontFamily="heading" whiteSpace="nowrap">
              {assetId}
            </Text>
          )}

          {showName && assetName && (
            <Text
              color={uiColors.sonicSilver()}
              fontSize="sm"
              lineHeight="1.25"
              noOfLines={canTruncate ? 1 : undefined}
            >
              {assetName}
            </Text>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default AssetLogo;
