import { useDisclosure } from '@chakra-ui/react';
import { createContext, ReactNode } from 'react';

import { useGetSystemStatus, useOnboardingInfo } from '~/hooks';

type Context = {
  // onboarding modal
  isOnboardingModalOpen: boolean;
  onOnboardingModalOpen: () => void;
  onOnboardingModalClose: () => void;

  // invest modal
  isInvestModalOpen: boolean;
  onInvestModalClose: () => void;

  // util fns
  onOpenModal: () => void;
  resumeOnboarding: () => void;
};

const GlobalModalContext = createContext({} as Context);

const GlobalModalProvider = ({ children }: { children: ReactNode }) => {
  const systemStatus = useGetSystemStatus();
  const { isOnboardedCrypto, isOnboardedSecurities } = useOnboardingInfo();
  const {
    isOpen: isOnboardingModalOpen,
    onOpen: onOnboardingModalOpen,
    onClose: onOnboardingModalClose,
  } = useDisclosure();

  const { isOpen: isInvestModalOpen, onOpen: onInvestModalOpen, onClose: onInvestModalClose } = useDisclosure();

  // dynamically open the onboarding or invest modal based on user state
  const onOpenModal = () => {
    systemStatus.refetch();

    if (isOnboardedSecurities || isOnboardedCrypto) {
      onInvestModalOpen();
    } else {
      onOnboardingModalOpen();
    }
  };

  // when a user selects an asset they aren't onboarded for, this provides a pathway directly into the onboarding modal
  const resumeOnboarding = () => {
    onInvestModalClose();
    onOnboardingModalOpen();
  };

  return (
    <GlobalModalContext.Provider
      value={{
        isOnboardingModalOpen,
        onOnboardingModalOpen,
        onOnboardingModalClose,

        isInvestModalOpen,
        onInvestModalClose,

        onOpenModal,
        resumeOnboarding,
      }}
    >
      {children}
    </GlobalModalContext.Provider>
  );
};

export { GlobalModalContext, GlobalModalProvider };
