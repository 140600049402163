'use client';

import { Button, Flex, FlexProps, Heading, Portal, Text } from '@chakra-ui/react';
import { formatPercentage, formatUsd, layoutMinWidth, uiColors } from '@cryptofi/core-ui';

import { investButtonLabels } from '~/constants';
import { useGlobalModalContext } from '~/hooks';

interface Props extends FlexProps {
  amountUsd?: number;
  gainLossPercentage?: number;
  assetId: string;
  ownsCurrentAsset: boolean;
}

const InvestmentCTA = ({ amountUsd = 0, assetId, gainLossPercentage = 0, ownsCurrentAsset, ...rest }: Props) => {
  const ctaBtnLabel = ownsCurrentAsset ? `${investButtonLabels.trade} ${assetId}` : investButtonLabels.invest;
  const { onOpenModal } = useGlobalModalContext();

  return (
    <Portal>
      <Flex
        position="fixed"
        bottom="0"
        zIndex="3"
        display={{ base: 'flex', md: 'none' }}
        justify="space-between"
        align="center"
        bg="white"
        w="full"
        p="4"
        borderTop="solid 1px"
        borderColor={uiColors.coolElegance()}
        minW={layoutMinWidth}
        {...rest}
      >
        {ownsCurrentAsset && (
          <Flex direction="column" gap="2">
            <Heading as="h1" size="xs">
              My investment
            </Heading>

            <Flex align="center" gap="2">
              <Text fontSize="xl" fontFamily="heading">
                {formatUsd({ amount: amountUsd })}
              </Text>

              <Text fontSize="sm" fontFamily="heading">
                {formatPercentage({ number: gainLossPercentage })}
              </Text>
            </Flex>
          </Flex>
        )}

        <Button w={ownsCurrentAsset ? 'auto' : 'full'} onClick={onOpenModal} display={{ base: 'block', lg: 'none' }}>
          {ctaBtnLabel}
        </Button>
      </Flex>
    </Portal>
  );
};

export default InvestmentCTA;
