'use client';

// eslint-disable-next-line no-restricted-imports
import { Button, CardHeader, Flex, Heading, Link, LinkBox, LinkOverlay, Skeleton, Text } from '@chakra-ui/react';
import { CfCard, CfPrettyWrap, dateFormats, IconExternalLink, uiColors } from '@cryptofi/core-ui';
import dayjs from 'dayjs';
import { memo, useEffect } from 'react';

import useGetNews from '~/hooks/api/useGetNews';
import { uiConstants } from '~/theme';

const News = () => {
  const results = 4; // keeps number of loading skeletons in sync with requested stories
  const news = useGetNews({ results });

  // fetched manually to opt out of default refetch behavior, which can cause new stories to load unexpectedly
  useEffect(() => {
    news.refetch();
  }, [news]);

  return (
    <CfCard>
      <CardHeader p="0" display="flex" justifyContent="space-between" alignItems="center">
        <Flex flexDir="column">
          <Heading as="h1" size="md">
            Latest news
          </Heading>

          <Text fontSize="xs" color={uiColors.sonicSilver()}>
            Provided by Yahoo Finance
          </Text>
        </Flex>

        <Button
          rightIcon={<IconExternalLink />}
          variant="ghost"
          as={Link}
          href="https://finance.yahoo.com/topic/stock-market-news/"
          textDecor="none !important"
          target="yahoo-finance"
        >
          View more
        </Button>
      </CardHeader>

      <Flex flexDir="column">
        {((news.isSuccess && news.data?.length === 0) || news.isError) && (
          <CfPrettyWrap textAlign="center" p="4">
            News is currently unavailable, please check back later.
          </CfPrettyWrap>
        )}

        {news.isLoading && (
          <Flex gap="2" w="full" flexDir="column">
            {Array.from({ length: results }).map((_, i) => {
              return <Skeleton key={i} h="4rem" />;
            })}
          </Flex>
        )}

        {news.data?.map((story) => {
          return (
            <LinkBox
              key={story.title}
              borderBottom={`dashed 1px ${uiColors.coolElegance()}`}
              _last={{ borderBottom: 'none', mb: -2 }}
            >
              <CfPrettyWrap
                my={2}
                p={2}
                borderRadius="md"
                transition={`background-color ${uiConstants.transitionDuration}`}
                _hover={{
                  bg: uiConstants.hoverBackground,
                }}
              >
                <Heading as="h3" fontSize="sm" mb="1">
                  <LinkOverlay
                    href={story.url}
                    target={encodeURIComponent(story.title)}
                    _hover={{ textDecor: 'underline' }}
                  >
                    {story.title}
                  </LinkOverlay>
                </Heading>

                <Text noOfLines={2} mb="2" fontSize="sm">
                  {story.summary}
                </Text>

                <Text fontSize="2xs" color={uiColors.sonicSilver()}>
                  {dayjs.utc(story.publicationDate).local().format(dateFormats.shortDateWithTime)}
                </Text>
              </CfPrettyWrap>
            </LinkBox>
          );
        })}
      </Flex>
    </CfCard>
  );
};

export default memo(News);
