import { Button, Tooltip } from '@chakra-ui/react';
import { IconRefresh } from '@cryptofi/core-ui';
import { Dispatch, SetStateAction } from 'react';
import { useFormContext } from 'react-hook-form';

import { AllAssetIds, TransactionCurrencies } from '~/customTypes';
import { useInvestModalContext } from '~/hooks';

import { TransactionFormValues } from './transactionSchema';

// switch between USD and selected asset
const ToggleCurrencyButton = ({
  currency,
  setCurrency,
  selectedAsset,
}: {
  currency: TransactionCurrencies;
  setCurrency: Dispatch<SetStateAction<TransactionCurrencies>>;
  selectedAsset: AllAssetIds;
}) => {
  const { transactionType } = useInvestModalContext();
  const { resetField, setValue, clearErrors } = useFormContext<TransactionFormValues>();

  return (
    <Tooltip label="Toggle currency" aria-label="Toggle currency" hasArrow offset={[0, 14]}>
      <Button
        minWidth="16"
        size="xs"
        variant="ghost"
        leftIcon={<IconRefresh __css={{ path: { fill: 'brand.500 !important' } }} />}
        onClick={() => {
          if (transactionType === 'buy') {
            // reset some state when toggling
            // defaultValue handles input not getting cleared after returning from review transaction
            resetField('buyCurrencyAmount');
            clearErrors('buyAccountId');

            // toggle between USD and selected asset
            setCurrency((currency === 'USD' ? selectedAsset : 'USD') as TransactionCurrencies);

            // update hidden input
            setValue('buyIsUsd', currency !== 'USD');
          }

          // same flow as above for sell
          if (transactionType === 'sell') {
            resetField('sellCurrencyAmount');
            setCurrency((currency === 'USD' ? selectedAsset : 'USD') as TransactionCurrencies);
            setValue('sellIsUsd', currency !== 'USD');
          }
        }}
      >
        {currency}
      </Button>
    </Tooltip>
  );
};

export default ToggleCurrencyButton;
