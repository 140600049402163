import { Text } from '@chakra-ui/react';
import { formatUsd, uiColors } from '@cryptofi/core-ui';
import Big from 'big.js';

import { useInvestModalContext } from '~/hooks';
import { isCrypto } from '~/utils';

// converts USD to selected asset or vice versa
const QuantityConversion = () => {
  const { buyCurrency, buyQuantity, buyPrice, sellCurrency, sellPrice, sellQuantity, transactionType, selectedAsset } =
    useInvestModalContext();

  // buy
  if (transactionType === 'buy') {
    const buyQuantityRounded = selectedAsset?.type === 'crypto' ? buyQuantity.toFixed(8) : buyQuantity.toFixed(5);
    return (
      <Text fontSize="sm" position="absolute" top="0.5" right="0" color={uiColors.sonicSilver()}>
        {buyCurrency === 'USD' && `≈ ${buyQuantityRounded} ${selectedAsset?.id}`}

        {buyCurrency !== 'USD' &&
          buyPrice &&
          buyQuantity &&
          `≈ ${formatUsd({
            amount: Big(buyPrice).mul(buyQuantity).toNumber(),
          })} USD`}
      </Text>
    );
  }

  // sell
  return (
    <Text fontSize="sm" position="absolute" top="0.5" right="0" color={uiColors.sonicSilver()}>
      {sellCurrency === 'USD' && `≈ ${sellQuantity} ${selectedAsset?.id}`}

      {sellCurrency !== 'USD' &&
        sellPrice &&
        sellQuantity &&
        `≈ ${formatUsd({
          amount: Big(sellPrice).mul(sellQuantity).toNumber(),
        })} USD`}
    </Text>
  );
};

export default QuantityConversion;
