import { useQuery } from '@tanstack/react-query';
import { camelize, Camelized, camelizeKeys } from 'humps';
import { capitalize, upperFirst } from 'lodash';

import { KYCFieldsResponse } from '~/codegen/types';
import { usStates } from '~/constants';
import { KycFieldTypes, KycFormField, KycFormFieldCollection, Product } from '~/customTypes';
import { useAxiosContext, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';

export const qkKycFields = 'qk:kycFields';

// transform select options string[] to object with text and value
const transformSelectOptions = (option: string) => {
  if (option === 'selfemployed') {
    return { name: 'Self-employed', value: option };
  }

  return { name: upperFirst(option), value: option };
};

const useGetKycFields = (args?: { enabled?: boolean; onboardingProducts: Product[] }) => {
  const userAuthInfo = useGlobalStore((state) => state.userAuthInfo);
  const { apiClient } = useAxiosContext();

  // product of undefined will make the request for all products
  const product = args?.onboardingProducts?.length === 1 ? args.onboardingProducts[0] : undefined;
  return useQuery({
    queryKey: [qkKycFields, product],
    enabled: args ? args.enabled && Boolean(userAuthInfo) : Boolean(userAuthInfo),
    queryFn: async () => {
      return apiClient?.get(apiRoutes.kycFields(product)).then((r) => {
        const data = camelizeKeys(r.data) as Camelized<KYCFieldsResponse>;
        const transformedNeed: KycFormField[] = [];
        const transformedHave: KycFormField[] = [];

        const transformField = (field: any, transformed: KycFormField[]) => {
          // create a copy of the field object with camelization and user friendly label
          const transformedField = {
            ...field,
            label: capitalize(field.label),
            name: camelize(field.name) as KycFormField['name'],
            valueType: camelize(field.valueType!) as KycFieldTypes,
          };

          // handle select options
          if (transformedField.options) {
            transformed.push({
              ...transformedField,
              options: transformedField.options.map(transformSelectOptions),
            });

            // add user friendly state names to abbreviated options
            if (field.name === 'state') {
              const transformedState = transformedNeed.find((f) => f.name === 'state');

              if (transformedState) {
                transformedState.options = transformedState.options?.map(({ value }) => {
                  return {
                    value,
                    name: `${value} - ${usStates[value as keyof typeof usStates]}`,
                  };
                });
              }
            }

            // add two letter country code to country names, currently limited to US only
            if (field.name === 'country') {
              const transformedCountry = transformed.find((f) => f.name === 'country');

              if (transformedCountry) {
                transformedCountry.options = transformedCountry.options?.map(({ name }) => {
                  return {
                    value: 'US',
                    name,
                  };
                });
              }
            }
          }
        };

        data.need?.forEach((f) => transformField(f, transformedNeed));
        data.have?.forEach((f) => transformField(f, transformedHave));

        const updatedData: KycFormFieldCollection = {
          have: transformedHave,
          need: transformedNeed,
        };

        return updatedData;
      });
    },
  });
};

export default useGetKycFields;
