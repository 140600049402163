// eslint-disable-next-line no-restricted-imports
import { Box, Link, Text } from '@chakra-ui/react';
import { formatPhoneNumber } from '@cryptofi/core-ui';

import { OnboardingStatus, SpecificProduct } from '~/customTypes';

const rejectedSecuritiesDescription = () => (
  <>
    <Text>
      Your account could not be approved for securities trading at this time. Please contact CDS1 Securities for further
      assistance if you think this is in error.
    </Text>

    <Box textAlign="left" pt="2">
      <Text>
        {'Email: '}

        <Link href="mailto:support@cdsecurities.co">support@cdsecurities.co</Link>
      </Text>

      <Text>
        {'Phone: '}

        <Link whiteSpace="nowrap" href="tel:8882552740">
          {formatPhoneNumber('8882552740')}
        </Link>
      </Text>

      <Text>Hours: Mon-Fri 9:00AM - 4:30PM ET</Text>
    </Box>
  </>
);

const rejectedCryptoDescription = () => (
  <Text>
    Your account could not be approved for crypto trading at this time. Please contact your financial institution if you
    think this is in error.
  </Text>
);

const pendingSecuritiesDescription = () => (
  <Text>
    Your application is currently under review for securities trading. You will receive an email from our support team
    if additional information is required.
  </Text>
);

const pendingCryptoDescription = () => (
  <Text>
    Your application is currently under review for crypto trading. You will receive an email from our support team if
    additional information is required.
  </Text>
);

export const kycAlertTextMap: Record<
  'rejected' | 'pending',
  { heading: Record<SpecificProduct, string>; description: Record<SpecificProduct, () => JSX.Element> }
> = {
  rejected: {
    heading: {
      securities: 'Unable to trade securities',
      crypto: 'Unable to trade crypto',
    },
    description: {
      securities: rejectedSecuritiesDescription,
      crypto: rejectedCryptoDescription,
    },
  },
  pending: {
    heading: {
      securities: 'Securities application under review',
      crypto: 'Crypto application under review',
    },
    description: {
      securities: pendingSecuritiesDescription,
      crypto: pendingCryptoDescription,
    },
  },
};
export const getKycAlertHeading = ({
  onboardingStatus,
  product,
}: {
  onboardingStatus?: OnboardingStatus;
  product: SpecificProduct;
}) =>
  Object.keys(kycAlertTextMap).includes(onboardingStatus ?? '')
    ? kycAlertTextMap[onboardingStatus as 'rejected' | 'pending'].heading[product]
    : undefined;

export const getKycAlertDescription = ({
  onboardingStatus,
  product,
}: {
  onboardingStatus?: OnboardingStatus;
  product: SpecificProduct;
}) =>
  Object.keys(kycAlertTextMap).includes(onboardingStatus ?? '')
    ? kycAlertTextMap[onboardingStatus as 'rejected' | 'pending'].description[product]
    : () => <></>;
