import { Flex, Text } from '@chakra-ui/react';

const TrustedContact = () => {
  return (
    <Flex gap="3" flexDir="column">
      <Text>
        A trusted contact is a person you authorize CDS1 Securities to contact in limited circumstances, such as if
        there’s a concern about activity in your account and they’ve been unable to get in touch with you.
      </Text>

      <Text>
        It can be a family member, attorney, accountant or another third-party whom you believe would respect your
        privacy and know how to handle the responsibility.
      </Text>

      <Text>The trusted contact has no ownership rights to the account now or in the future.</Text>
    </Flex>
  );
};

export default TrustedContact;
