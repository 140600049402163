import { useQuery } from '@tanstack/react-query';
import { Camelized, camelizeKeys } from 'humps';

import { UserComplianceSuitabilitySettingsRequestModel } from '~/codegen/types';
import { apiRoutes } from '~/routes';
import { ignoreUpperCaseKeys } from '~/utils';

import { useAxiosContext } from './useAxiosContext';

export const qkSuitabilitySettings = 'qk:complianceSuitabilitySettings';

const useGetComplianceSuitabilitySettings = () => {
  const { apiClient } = useAxiosContext();

  return useQuery({
    queryKey: [qkSuitabilitySettings],
    queryFn: async () => {
      return apiClient?.get(apiRoutes.userComplianceSuitabilitySettings()).then(
        (r) =>
          camelizeKeys(r.data, {
            process: ignoreUpperCaseKeys,
          }) as Camelized<UserComplianceSuitabilitySettingsRequestModel>,
      );
    },
  });
};

export default useGetComplianceSuitabilitySettings;
