import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { decamelizeKeys } from 'humps';

import { UserValues } from '~/codegen/types';
import { Product } from '~/customTypes';
import { qkUser, useAxiosContext } from '~/hooks';
import { apiRoutes } from '~/routes';

const usePostKyc = ({
  options,
  onboardingProducts,
}: {
  options?: UseMutationOptions<void, AxiosError, UserValues>;
  onboardingProducts: Product[];
}) => {
  const queryClient = useQueryClient();
  const { apiClient } = useAxiosContext();
  // product of undefined will make the request for all products
  const product = onboardingProducts.length === 1 ? onboardingProducts[0] : undefined;
  return useMutation({
    mutationFn: async (body) => {
      return apiClient?.post(apiRoutes.kyc(product), decamelizeKeys(body));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.includes(qkUser);
        },
      });
    },
    ...options,
  });
};

export default usePostKyc;
