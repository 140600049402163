import React from 'react';

import InvestModal from '~/components/InvestModal/InvestModal';
import OnboardingModal from '~/components/Onboarding/Modal/Modal'; // TODO rename
import { InvestModalProvider } from '~/components/Providers/InvestModalProvider';
import { AllAssetIds, AssetType } from '~/customTypes';
import { useGlobalModalContext, useOnboardingInfo } from '~/hooks';

import DisclosuresModal from './DisclosuresModal';

const GlobalModals = ({
  defaultAssetId,
  defaultAssetType,
}: {
  defaultAssetId?: AllAssetIds;
  defaultAssetType?: AssetType;
}) => {
  const onboardingInfo = useOnboardingInfo();
  const { hasNewTerms } = onboardingInfo;
  const { isOnboardingModalOpen, onOnboardingModalClose, isInvestModalOpen, onInvestModalClose } =
    useGlobalModalContext();

  return (
    <>
      <DisclosuresModal />

      <OnboardingModal
        onboardingInfo={onboardingInfo}
        isOpen={isOnboardingModalOpen}
        onClose={onOnboardingModalClose}
        hasNewTerms={hasNewTerms}
      />

      {defaultAssetId && (
        <InvestModalProvider defaultAssetId={defaultAssetId} defaultAssetType={defaultAssetType}>
          <InvestModal isOpen={isInvestModalOpen} onClose={onInvestModalClose} />
        </InvestModalProvider>
      )}
    </>
  );
};

export default GlobalModals;
