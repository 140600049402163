import { useQuery } from '@tanstack/react-query';
import { camelizeKeys } from 'humps';

import { SipData } from '~/customTypes';
import { useAxiosContext, useFeatureSetEnabled, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';

export const qkSecuritySipPrice = 'qk:securitySipPrice';

const useGetSecuritySipPrice = ({
  ticker,
  enabled = true,
  refetchInterval,
}: {
  ticker: string;
  enabled?: boolean;
  refetchInterval?: number;
}) => {
  const { apiClient } = useAxiosContext();
  const [userAuthInfo] = useGlobalStore((state) => [state.userAuthInfo]);
  const disableRefetch = localStorage.getItem('enableRefetch') === 'false';
  const { isEnabled } = useFeatureSetEnabled();

  return useQuery({
    staleTime: 0,
    enabled: Boolean(userAuthInfo) && isEnabled(['securities']) && enabled,
    queryKey: [qkSecuritySipPrice, ticker, refetchInterval],
    refetchInterval: disableRefetch ? false : refetchInterval,
    queryFn: async () => {
      const response = await apiClient?.get(apiRoutes.intrinio.getSecuritySipPrice({ ticker }));
      return camelizeKeys(response?.data) as SipData;
    },
  });
};

export default useGetSecuritySipPrice;
