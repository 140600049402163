import { useQuery } from '@tanstack/react-query';
import { camelizeKeys } from 'humps';

import { NewsStory } from '~/customTypes';
import { useAxiosContext } from '~/hooks';
import { apiRoutes } from '~/routes';

export const qkNews = 'qk:news';

const useGetNews = ({ results }: { results?: number }) => {
  const { apiClient } = useAxiosContext();

  return useQuery({
    enabled: false,
    queryKey: [qkNews, results],
    queryFn: async () => {
      const response = await apiClient?.get(apiRoutes.intrinio.getNews({ results }));

      return camelizeKeys(response?.data) as NewsStory[];
    },
  });
};

export default useGetNews;
