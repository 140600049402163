import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Camelized, camelizeKeys, decamelizeKeys } from 'humps';

import { UserComplianceSuitabilitySettingsRequestModel } from '~/codegen/types';
import { qkSuitabilitySettings, qkUser, useAxiosContext } from '~/hooks';
import { apiRoutes } from '~/routes';
import { ignoreUpperCaseKeys } from '~/utils';

const usePostComplianceSuitabilitySettings = () => {
  const { apiClient } = useAxiosContext();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Camelized<UserComplianceSuitabilitySettingsRequestModel>) => {
      return apiClient?.post(apiRoutes.userComplianceSuitabilitySettings(), decamelizeKeys(data)).then(
        (r) =>
          camelizeKeys(r.data, {
            process: ignoreUpperCaseKeys,
          }) as Camelized<UserComplianceSuitabilitySettingsRequestModel>,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.includes(qkUser) || // invalidate the user query to refetch assets when risk tolerance changes
          query.queryKey.includes(qkSuitabilitySettings),
      });
    },
  });
};

export default usePostComplianceSuitabilitySettings;
