import { Big } from 'big.js';

//TODO: move this function to Core UI
export const formatCurrencyWithSuffix = (amount: number): string => {
  const suffixes = [
    { value: 1e12, symbol: 'T' }, // trillion
    { value: 1e9, symbol: 'B' }, // billion
    { value: 1e6, symbol: 'M' }, // million
    { value: 1e3, symbol: 'K' }, // thousand
  ];

  const suffix = suffixes.find((item) => amount >= item.value);

  if (!suffix) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  }

  const formattedNumber = Big(amount).div(suffix.value).toFixed(2);
  return `$${formattedNumber} ${suffix.symbol}`;
};
