import { useQuery } from '@tanstack/react-query';
import { camelizeKeys } from 'humps';

import { bankAccountRefetchInterval } from '~/constants';
import { BankAccount } from '~/customTypes';
import { useAxiosContext, useGlobalStore, useOnboardingInfo } from '~/hooks';
import { apiRoutes } from '~/routes';

export const qkUserBankAccounts = 'qk:userBankAccounts';

const useGetUserBankAccounts = (args?: { refetchInterval?: number | false }) => {
  const { isOnboarded } = useOnboardingInfo();

  const userAuthInfo = useGlobalStore((state) => state.userAuthInfo);
  const { apiClient } = useAxiosContext();
  const disableRefetch = localStorage.getItem('enableRefetch') === 'false';
  const refetchInterval = args?.refetchInterval ?? bankAccountRefetchInterval;

  return useQuery({
    queryKey: [qkUserBankAccounts, refetchInterval],
    enabled: Boolean(userAuthInfo) && isOnboarded, // only fetch if user is onboarded
    refetchInterval: disableRefetch ? false : refetchInterval,
    queryFn: async () => {
      return apiClient?.get(apiRoutes.bankAccounts()).then((r) => {
        return camelizeKeys(r.data) as BankAccount[];
      });
    },
  });
};

export default useGetUserBankAccounts;
