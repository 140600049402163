/* eslint-disable no-restricted-syntax */
import Big from 'big.js';
import dayjs from 'dayjs';
import { Camelized } from 'humps';

import {
  AssetBalance,
  InvestmentHistoricalValue,
  RedisSecurityInfo,
  RedisSecurityPricingInfo,
  TokenPriceResponse,
  User,
} from '~/codegen/types';
import { Investment } from '~/customTypes';

export const calculatePercentangeChange = ({
  initialValue,
  currentValue,
}: {
  initialValue?: number;
  currentValue?: number;
}) => {
  if (!initialValue || !currentValue || initialValue <= 0 || currentValue <= 0) {
    return 0;
  }
  const priceDifference = new Big(currentValue).minus(initialValue);
  const percentChange = priceDifference.div(initialValue).toNumber();
  return percentChange;
};

export function calculateTotalBalance(investments: Investment[]): number {
  return investments.reduce((acc, inv) => acc.plus(inv.amountUsd || 0), Big(0)).toNumber();
}

export function calculateGainLoss(investments: Investment[]) {
  const totalGainLoss = investments.reduce((acc, inv) => {
    const gainLoss = inv.delta?.allTime?.totalGainLoss || 0;
    return acc.plus(gainLoss);
  }, Big(0));

  const totalBalance = calculateTotalBalance(investments);
  const totalInvestedAmount = Big(totalBalance).minus(totalGainLoss).toNumber();
  const gainLossAmount = totalGainLoss.toNumber();
  const gainLossPercent = totalInvestedAmount !== 0 ? totalGainLoss.div(totalInvestedAmount).toNumber() : 0;
  return { amount: gainLossAmount, percent: gainLossPercent };
}

export function calculateSecurityInvestments({
  securityPrices,
  userSecurities,
}: {
  securityPrices: Camelized<RedisSecurityInfo>[];
  userSecurities: Camelized<Record<string, AssetBalance>>;
}) {
  const investments: Investment[] = [];

  securityPrices.forEach((security) => {
    const securityBalanceData = userSecurities?.[security.securitiesTicker || ''];
    if (security.securitiesTicker && securityBalanceData) {
      const amountOwned = Number(securityBalanceData.amountOwned || 0);
      if (amountOwned === 0) {
        return;
      }
      const currentPrice = Number(security.currentPrice?.value || 0);
      const avgCost = Number(securityBalanceData.avgCost || 0);
      const amountUsd = Big(currentPrice).times(amountOwned).toNumber();

      const percentChangeDaily = calculatePercentangeChange({
        initialValue: Number(security?.lastTradingDayInfo?.closePrice),
        currentValue: currentPrice,
      });

      const percentChangeAllTime = calculatePercentangeChange({
        initialValue: avgCost,
        currentValue: currentPrice,
      });

      const allTimeGainLoss = new Big(amountUsd).minus(new Big(avgCost).times(amountOwned)).toNumber();

      const dailyGainLoss = new Big(amountUsd).minus(new Big(avgCost).times(amountOwned)).toNumber();

      investments.push({
        assetId: security.securitiesTicker,
        assetType: 'securities',
        assetName: security.name || undefined,
        currentPrice,
        avgCost,
        amountOwned,
        sparkline: undefined,
        delta: {
          allTime: {
            percentChange: percentChangeAllTime,
            isGain: percentChangeAllTime >= 0,
            totalGainLoss: allTimeGainLoss,
          },
          daily: {
            percentChange: percentChangeDaily,
            isGain: percentChangeDaily >= 0,
            totalGainLoss: dailyGainLoss,
          },
        },
        amountUsd,
      });
    }
  });

  return investments;
}

export function calculateCryptoInvestments(cryptoPrices: Camelized<TokenPriceResponse>[]) {
  const investments = cryptoPrices
    .filter((d) => d.amountOwned !== 0)
    .map((inv) => {
      const currentPrice = Number(inv.buyPrice);
      const percentChangeAllTime = calculatePercentangeChange({
        initialValue: inv.avgCost,
        currentValue: currentPrice,
      });
      const allTimeGainLoss = new Big(inv.amountUsd).minus(new Big(inv.avgCost).times(inv.amountOwned)).toNumber();

      const percentChangeDaily = calculatePercentangeChange({
        initialValue: Number(inv.sparkline?.[0]?.value || 0),
        currentValue: currentPrice,
      });
      const dailyGainLoss = new Big(inv.amountUsd).minus(new Big(inv.avgCost).times(inv.amountOwned)).toNumber();

      const investment: Investment = {
        assetId: inv.baseToken,
        assetType: 'crypto',
        assetName: inv.tokenName,
        currentPrice,
        avgCost: inv.avgCost,
        amountUsd: inv.amountUsd,
        amountOwned: inv.amountOwned,
        sparkline: inv.sparkline,
        delta: {
          allTime: {
            percentChange: percentChangeAllTime,
            isGain: percentChangeAllTime >= 0,
            totalGainLoss: allTimeGainLoss,
          },
          daily: {
            percentChange: percentChangeDaily,
            isGain: percentChangeDaily >= 0,
            totalGainLoss: dailyGainLoss,
          },
        },
      };

      return investment;
    });

  return investments;
}

export function forwardFillChartData({
  chartData,
  intervalMinutes,
}: {
  chartData: Camelized<RedisSecurityPricingInfo>[];
  intervalMinutes: number;
}) {
  const currentLength = chartData?.length || 0;
  // Get the last data point's date
  const lastDate = chartData?.[currentLength - 1]?.date;
  if (!lastDate) {
    return chartData;
  }
  // Calculate end of trading day (4:00 PM EST)
  const endOfTradingDay = dayjs().tz('America/New_York').hour(16).minute(0).second(0).millisecond(0);

  // If last date is after end of trading day, return original data
  if (dayjs(lastDate).isAfter(endOfTradingDay)) {
    return chartData;
  }

  const forwardFillData: Camelized<RedisSecurityPricingInfo>[] = [];
  let currentDate = dayjs(lastDate);

  while (
    dayjs().isBefore(endOfTradingDay) && // ffill during trading hours
    currentDate.isBefore(endOfTradingDay) && // ffill data until end of trading day.
    forwardFillData.length < currentLength // stop ffill after blank space is half of the chart
  ) {
    currentDate = currentDate.add(intervalMinutes, 'minutes');
    if (currentDate.isBefore(endOfTradingDay)) {
      forwardFillData.push({
        date: currentDate.toISOString(),
        value: null,
      } as Camelized<RedisSecurityPricingInfo>);
    }
  }

  return [...chartData, ...forwardFillData];
}

export function backFillChartData({
  chartData,
  timeRange,
}: {
  chartData: {
    date: string;
    value: string;
  }[];
  timeRange: keyof Camelized<InvestmentHistoricalValue>;
}) {
  const currentLength = chartData.length;
  const earliestDate = chartData?.[0]?.date;
  const oneHour = 1000 * 60 * 60;

  const timeRangeTargetLengths: Record<
    keyof Camelized<InvestmentHistoricalValue>,
    { backFillLength: number; backFillInterval: number }
  > = {
    pastDay: { backFillLength: 24, backFillInterval: oneHour },
    pastWeek: { backFillLength: 168, backFillInterval: oneHour },
    pastMonth: { backFillLength: 31, backFillInterval: oneHour * 24 },
    pastYear: { backFillLength: 366, backFillInterval: oneHour * 24 },
    // allTime has short backfill since there are not a lot of data points and 1 data point looks bad
    allTime: { backFillLength: 3, backFillInterval: oneHour * 24 },
  };

  const backfillCount = Math.max(0, timeRangeTargetLengths[timeRange].backFillLength - currentLength);

  const backfillData = Array(backfillCount)
    .fill(null)
    .map((_, index) => ({
      date: new Date(
        new Date(earliestDate).getTime() - (backfillCount - index) * timeRangeTargetLengths[timeRange].backFillInterval,
      ).toISOString(),
      value: '0',
    }));

  return [...backfillData, ...chartData];
}

// updates the last value in each time series to the current allInvestments value
export function updateInvestmentValueTimeSeries({
  investmentValueTimeSeries,
  allInvestmentsBalance,
}: {
  investmentValueTimeSeries: Camelized<InvestmentHistoricalValue> | undefined;
  allInvestmentsBalance: string;
}) {
  if (investmentValueTimeSeries) {
    return Object.fromEntries(
      Object.entries(investmentValueTimeSeries).map(([key, timeSeries]) => {
        if (Array.isArray(timeSeries)) {
          // if no data in array use the current allInvestments value
          const arrayWithCurrentValue = [
            ...timeSeries,
            { date: new Date().toISOString(), value: allInvestmentsBalance },
          ];
          const backfilledArray = backFillChartData({
            chartData: arrayWithCurrentValue,
            timeRange: key as keyof Camelized<InvestmentHistoricalValue>,
          });
          return [key, backfilledArray];
        }
        // default case to return the original value
        return [key, timeSeries];
      }),
    );
  }
}

// used to determine when to show PerformanceOverTime component
export const hasPerformanceData = ({
  user,
  investmentCount = 0,
}: {
  user?: Camelized<User>;
  investmentCount?: number;
}): boolean => {
  const totalOrders = user?.balance?.totalOrders || 0;
  return totalOrders > 0 || investmentCount > 0;
};
