import Big from 'big.js';
import { size } from 'lodash';
import { useMemo, useState } from 'react';

import { InvestmentGroup } from '~/customTypes';
import { useFeatureSetEnabled, useGetSecurities, useGetTokenPrices, useGetUser } from '~/hooks';
import {
  calculateCryptoInvestments,
  calculateGainLoss,
  calculateSecurityInvestments,
  calculateTotalBalance,
} from '~/utils/investments';

const useUserInvestments = () => {
  const { isEnabled } = useFeatureSetEnabled();
  const user = useGetUser();

  const tickers = Object.keys(user.data?.balance?.securities || {}).join(',');
  const securityPrices = useGetSecurities({ tickers, refetchInterval: 60_000 });
  const cryptoPrices = useGetTokenPrices({ refetchInterval: 60_000 });

  const [isCalculating, setIsCalculating] = useState({
    securities: false,
    crypto: false,
  });

  const securityInvestments: InvestmentGroup = useMemo(() => {
    if (securityPrices.data && user.data?.balance?.securities) {
      setIsCalculating({ ...isCalculating, securities: true });
      const assets = calculateSecurityInvestments({
        securityPrices: securityPrices.data,
        userSecurities: user?.data.balance.securities,
      });
      const totalAmountUsd = calculateTotalBalance(assets);
      const gainLossAmount = calculateGainLoss(assets);
      setIsCalculating({ ...isCalculating, securities: false });
      return {
        assets,
        totalAmountUsd,
        gainLossAmount,
        size: size(assets),
      };
    }
    return { assets: [], totalAmountUsd: 0, gainLossAmount: { amount: 0, percent: 0 }, size: 0 };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityPrices.data, user.data?.balance?.securities]);

  const cryptoInvestments: InvestmentGroup = useMemo(() => {
    setIsCalculating({ ...isCalculating, crypto: true });
    const assets = calculateCryptoInvestments(cryptoPrices.data || []);
    const totalAmountUsd = calculateTotalBalance(assets);
    const gainLossAmount = calculateGainLoss(assets);
    setIsCalculating({ ...isCalculating, crypto: false });
    return {
      assets,
      totalAmountUsd,
      gainLossAmount,
      size: size(assets),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cryptoPrices.data]);

  const allInvestments: InvestmentGroup = useMemo(() => {
    return {
      assets: [...securityInvestments.assets, ...cryptoInvestments.assets],
      totalAmountUsd: Big(cryptoInvestments.totalAmountUsd).plus(securityInvestments.totalAmountUsd).toNumber(),
      gainLossAmount: {
        amount: Big(cryptoInvestments.gainLossAmount.amount).plus(securityInvestments.gainLossAmount.amount).toNumber(),
        percent: Big(cryptoInvestments.gainLossAmount.percent)
          .plus(securityInvestments.gainLossAmount.percent)
          .toNumber(),
      },
      size: Big(securityInvestments.size).plus(cryptoInvestments.size).toNumber(),
    };
  }, [securityInvestments, cryptoInvestments]);

  const isSuccessSecurities = isEnabled(['securities']) ? securityPrices.isSuccess : true;
  const isSuccessCrypto = isEnabled(['crypto']) ? cryptoPrices.isSuccess : true;
  const isLoadingSecurities = isEnabled(['securities']) ? securityPrices.isLoading : false;
  const isLoadingCrypto = isEnabled(['crypto']) ? cryptoPrices.isLoading : false;
  return {
    isSuccess: isSuccessSecurities || isSuccessCrypto || !tickers,
    isLoading: isLoadingSecurities || isLoadingCrypto || isCalculating.securities || isCalculating.crypto,
    isError: securityPrices.isError || cryptoPrices.isError,
    data: {
      securityInvestments,
      cryptoInvestments,
      allInvestments,
    },
  };
};

export default useUserInvestments;
