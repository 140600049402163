import { useQuery } from '@tanstack/react-query';
import { Camelized, camelizeKeys } from 'humps';

import { StatusResponse } from '~/codegen/types';
import { statusRefetchInterval } from '~/constants';
import { useAxiosContext } from '~/hooks';
import { apiRoutes } from '~/routes';

interface SystemStatus {
  isCryptoAvailable: Camelized<StatusResponse>;
  isSecuritiesAvailable: Camelized<StatusResponse>;
  securitiesTradingOpen: Camelized<StatusResponse>;
}

export const qkSystemStatus = 'qk:systemStatus';

const useGetSystemStatus = () => {
  const { apiClient } = useAxiosContext();
  const disableRefetch = localStorage.getItem('enableRefetch') === 'false';

  return useQuery({
    refetchInterval: disableRefetch ? false : statusRefetchInterval,
    queryKey: [qkSystemStatus],
    queryFn: async () => {
      return apiClient?.get(apiRoutes.systemStatus()).then((r) => {
        if (localStorage.getItem('systemState')) {
          const { isSecuritiesAvailable, isCryptoAvailable, securitiesTradingOpen } = JSON.parse(
            localStorage.getItem('systemState')!,
          );

          return {
            isCryptoAvailable,
            isSecuritiesAvailable,
            securitiesTradingOpen,
          };
        }

        const camelized = camelizeKeys(r.data) as SystemStatus;

        return {
          isCryptoAvailable: camelized.isCryptoAvailable.status === 'OK',
          isSecuritiesAvailable: camelized.isSecuritiesAvailable.status === 'OK',
          securitiesTradingOpen: camelized.securitiesTradingOpen.status === 'OK',
        };
      });
    },
  });
};

export default useGetSystemStatus;
