import { CardFooter, Flex, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text } from '@chakra-ui/react';
import { formatUsd, uiColors } from '@cryptofi/core-ui';

import { TrendIndicator } from '~/components';
import { formatCurrencyWithSuffix } from '~/utils';

export const PerformanceOverTimeFooter = ({
  view,
  metrics,
}: {
  view: 'portfolio' | 'asset';
  metrics?: {
    assetMetrics?: {
      marketVolume?: number;
      marketCap?: number;
      lowPrice?: number;
      highPrice?: number;
      lastPrice?: number;
    };
    portfolioMetrics?: {
      gainLossAmount?: number;
      gainLossPercent?: number;
    };
  };
}) => {
  return (
    <CardFooter padding={{ base: '0', md: '4' }}>
      {view === 'portfolio' && (
        <Flex
          gap={{ base: 4, sm: 8 }}
          justifyContent={{ base: 'space-around', sm: 'flex-start' }}
          w="full"
          p="2"
          flexWrap="wrap"
        >
          {Boolean(metrics?.portfolioMetrics?.gainLossAmount) && (
            <TrendIndicator
              heading={Number(metrics?.portfolioMetrics?.gainLossAmount) >= 0 ? 'Gain' : 'Loss'}
              isNetGain={Number(metrics?.portfolioMetrics?.gainLossAmount) >= 0}
              amount={metrics?.portfolioMetrics?.gainLossAmount}
            />
          )}

          {Boolean(metrics?.portfolioMetrics?.gainLossPercent) && (
            <TrendIndicator
              heading="Return"
              isNetGain={Number(metrics?.portfolioMetrics?.gainLossPercent) >= 0}
              percent={metrics?.portfolioMetrics?.gainLossPercent}
            />
          )}
        </Flex>
      )}

      {view === 'asset' && metrics && (
        <Flex gap={{ base: 4, md: '10' }} direction={{ base: 'column', md: 'row' }} w="full" p="2">
          {metrics?.assetMetrics?.lowPrice && metrics?.assetMetrics?.highPrice && metrics?.assetMetrics?.lastPrice && (
            <Flex
              flexDir={{ base: 'row', md: 'column' }}
              justifyContent={{ base: 'space-between', md: 'flex-start' }}
              alignItems={{ base: 'center', md: 'initial' }}
              w={{ base: 'full', md: 'auto' }}
              flexGrow="1"
              flexShrink="0"
              minW={{ base: 'auto', md: '18rem' }}
              maxW={{ md: '40%' }}
              gap={{ base: 4, md: 2 }}
            >
              <Text color={uiColors.sonicSilver()} whiteSpace="nowrap">
                24h High and Low
              </Text>

              <Flex direction={{ base: 'column', md: 'row' }} flexGrow="1" gap={{ base: 0, md: 2 }}>
                <Flex display={{ base: 'flex', md: 'none' }} w="full" justifyContent="space-between">
                  <Text fontFamily="heading" fontSize="xs">
                    {formatUsd({ amount: metrics?.assetMetrics?.lowPrice })}
                  </Text>

                  <Text fontFamily="heading" fontSize="xs">
                    {formatUsd({ amount: metrics?.assetMetrics?.highPrice })}
                  </Text>
                </Flex>

                <Flex alignItems="center" gap="2" w="full">
                  <Text display={{ base: 'none', md: 'flex' }} fontFamily="heading" fontSize="xs">
                    {formatUsd({ amount: metrics?.assetMetrics?.lowPrice })}
                  </Text>

                  <Slider
                    cursor="default"
                    isReadOnly
                    w="full"
                    min={metrics?.assetMetrics?.lowPrice}
                    max={metrics?.assetMetrics?.highPrice}
                    aria-label="24 hour low / current high price range"
                    value={metrics?.assetMetrics?.lastPrice}
                  >
                    {/* TODO: uncomment to show the current price */}
                    {/* <SliderMark
                        value={metrics.lastPrice}
                        transform="translateX(-50%)"
                        mt="2"
                        fontFamily="heading"
                        fontSize="xs"
                      >
                        {formatUsd({ amount: metrics.lastPrice })}
                      </SliderMark> */}

                    <SliderTrack
                      h={2}
                      borderRadius="full"
                      border="1px solid"
                      bg={uiColors.lighthouse()}
                      borderColor={uiColors.coolElegance()}
                    >
                      <SliderFilledTrack bg="white" borderLeftRadius="full" borderRightRadius="none" />

                      <SliderThumb
                        w="1px"
                        h="full"
                        bgColor={uiColors.coolElegance()}
                        outline="none"
                        boxShadow="none"
                        border="none"
                      />
                    </SliderTrack>
                  </Slider>

                  <Text display={{ base: 'none', md: 'flex' }} fontFamily="heading" fontSize="xs">
                    {formatUsd({ amount: metrics?.assetMetrics?.highPrice })}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          )}

          {metrics?.assetMetrics?.marketVolume && (
            <Flex
              flexDir={{ base: 'row', md: 'column' }}
              justifyContent={{ base: 'space-between', md: 'flex-start' }}
              w={{ base: 'full', md: 'auto' }}
              flex={0}
              gap="2"
            >
              <Text whiteSpace="nowrap" color={uiColors.sonicSilver()}>
                Volume
              </Text>

              <Text fontFamily="heading" fontSize="sm">
                {new Intl.NumberFormat().format(metrics?.assetMetrics?.marketVolume)}
              </Text>
            </Flex>
          )}

          {metrics?.assetMetrics?.marketCap && (
            <Flex
              flexDir={{ base: 'row', md: 'column' }}
              justifyContent={{ base: 'space-between', md: 'flex-start' }}
              w={{ base: 'full', md: 'auto' }}
              flex={0}
              gap="2"
            >
              <Text whiteSpace="nowrap" color={uiColors.sonicSilver()}>
                Market Cap
              </Text>

              <Text fontFamily="heading" fontSize="sm">
                {formatCurrencyWithSuffix(metrics?.assetMetrics?.marketCap)}
              </Text>
            </Flex>
          )}
        </Flex>
      )}
    </CardFooter>
  );
};
