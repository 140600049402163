'use client';

import { Box, Button, CardHeader, Flex, FlexProps, Show, Skeleton, Text } from '@chakra-ui/react';
import { CfAreaChart, CfCard, formatUsd, getDelta, uiColors } from '@cryptofi/core-ui';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction } from 'react';

import { TelemetryClientSideEventsEnum } from '~/codegen/types';
import { investButtonLabels } from '~/constants';
import { AllAssetIds, AssetType, ChartTimeRange } from '~/customTypes';
import { usePostTelemetryEvent } from '~/hooks';
import { isCrypto } from '~/utils';
import chartDateFormatter from '~/utils/chartDateFormatter';
import { forwardFillChartData } from '~/utils/investments';
import { getLastStockMarketTradingDay, getTimeUntilMarketOpen } from '~/utils/securitiesSchedule';

import CurrentValue from './CurrentValue';
import { PerformanceOverTimeFooter } from './PerformanceOverTimeFooter';
import TimeRangeSelector from './TimeRangeSelector';

interface Props extends FlexProps {
  selectedTimeRange: ChartTimeRange;
  setSelectedTimeRange: Dispatch<SetStateAction<ChartTimeRange>>;
  chartData: {
    value?: string | null;
    date?: string | null;
  }[];
  currentValue: number;
  gainLossAmount?: number | string;
  gainLossPercent?: number | string;
  isLoaded: boolean;
  view: 'portfolio' | 'asset';
  assetId?: AllAssetIds;
  assetType?: AssetType;
  noDataMessage?: string;
  footerMetrics?: React.ComponentProps<typeof PerformanceOverTimeFooter>['metrics'];
  onOpenModal?: () => void;
}

const PerformanceOverTime = ({
  selectedTimeRange,
  setSelectedTimeRange,
  chartData,
  currentValue,
  isLoaded,
  view,
  assetId,
  assetType,
  noDataMessage = 'No data available',
  footerMetrics,
  onOpenModal,
  ...rest
}: Props) => {
  const { priceInterval } = getTimeUntilMarketOpen();
  const { delta: chartDataDelta, isNetGain: chartDataIsNetGain } = getDelta({
    data: chartData,
    dataKey: 'value',
  });
  const isDailySecuritiesChart = assetId && !isCrypto(assetId) && selectedTimeRange === 'pastDay';
  const chartHeight = { base: '12rem', lg: '16rem' };
  const hasInvestments = currentValue > 0;
  const ctaBtnLabel = hasInvestments ? investButtonLabels.trade : investButtonLabels.invest;
  const { trackEvent } = usePostTelemetryEvent();

  // Handle "Investment" button clicks; Fire tracking event, then the original onClick action
  const handleInvestButtonClick = () => {
    trackEvent(TelemetryClientSideEventsEnum.ClickedCryptoHomeInvestButtonClient);
    onOpenModal?.();
  };

  const hasData = chartData && chartData.length > 0;

  const getSecurityDailyTimeRangeLabel = () => {
    // dynamically returns a label of the last trading day compared to todays date (today, yesterday, last Thursday, or possibly 11/03/2024)
    return dayjs(getLastStockMarketTradingDay()).calendar(dayjs(), {
      sameDay: '[today]',
      lastDay: '[yesterday]',
      lastWeek: '[last] dddd',
      sameElse: 'MM/DD/YYYY',
    });
  };

  return (
    <CfCard p="0" gap="0" zIndex={3} {...rest}>
      <CardHeader pb="0">
        <Flex justifyContent="space-between" alignItems="flex-end" w="full" p="4" pr="0">
          <CurrentValue
            currentValue={currentValue}
            isNetGain={chartDataIsNetGain}
            delta={chartDataDelta}
            isLoaded={isLoaded}
            view={view}
            assetId={assetId}
            assetType={assetType}
            selectedTimeRange={selectedTimeRange}
            customTimeRangeLabel={isDailySecuritiesChart ? getSecurityDailyTimeRangeLabel() : undefined}
          />

          {onOpenModal && (
            // button is hidden on mobile and shown in the trade portal from the home page
            <Show above="lg">
              <Button size="lg" fontSize="md" onClick={handleInvestButtonClick}>
                {ctaBtnLabel}
              </Button>
            </Show>
          )}
        </Flex>

        <Box borderTop="solid 1px" borderColor={uiColors.coolElegance()}>
          <TimeRangeSelector selectedTimeRange={selectedTimeRange} setSelectedTimeRange={setSelectedTimeRange} />
        </Box>
      </CardHeader>

      <Skeleton isLoaded={isLoaded}>
        <Flex position="relative" alignItems="center" justifyContent="center" w="full" height={chartHeight}>
          {!hasData && <Text>{noDataMessage}</Text>}

          {hasData && (
            <Box position="absolute" top="0" left="0" right="0" bottom="0">
              <CfAreaChart
                data={
                  isDailySecuritiesChart
                    ? forwardFillChartData({ chartData, intervalMinutes: parseInt(priceInterval) })
                    : chartData
                }
                dataKey="value"
                height="inherit"
                formatter={(amount) => String(formatUsd({ amount }))}
                labelFormatter={(_, chartProps) => {
                  if (chartProps.length) {
                    return chartDateFormatter({ date: chartProps[0].payload.date, selectedTimeRange });
                  }
                }}
              />
            </Box>
          )}
        </Flex>
      </Skeleton>

      <PerformanceOverTimeFooter view={view} metrics={footerMetrics} />
    </CfCard>
  );
};

export default PerformanceOverTime;
