import { useQuery } from '@tanstack/react-query';
import { camelizeKeys } from 'humps';

import { UserAddress } from '~/customTypes';
import { useAxiosContext, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';

export const qkUserAddress = 'qk:userAddress';

const useGetUserAddress = () => {
  const { apiClient } = useAxiosContext();
  const userAuthInfo = useGlobalStore((state) => state.userAuthInfo);

  return useQuery({
    enabled: Boolean(userAuthInfo),
    queryKey: ['qk:userAddress'],
    queryFn: async () => {
      const response = await apiClient?.get(apiRoutes.userAddress());
      return camelizeKeys(response?.data) as UserAddress;
    },
  });
};

export default useGetUserAddress;
