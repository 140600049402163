import * as Sentry from '@sentry/browser';
import { useQuery } from '@tanstack/react-query';
import { Camelized, camelizeKeys } from 'humps';

import { UserResponse } from '~/codegen/types';
import { useAxiosContext, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';
import { ignoreUpperCaseKeys } from '~/utils';

export const qkUser = 'qk:user';

const useGetUser = () => {
  const userAuthInfo = useGlobalStore((state) => state.userAuthInfo);
  const { apiClient } = useAxiosContext();

  const { data, isError, refetch, isRefetching } = useQuery({
    enabled: Boolean(userAuthInfo),
    queryKey: ['qk:user'],
    queryFn: async () => {
      return apiClient?.get(apiRoutes.user()).then((r) => {
        const camelized = camelizeKeys(r.data, { process: ignoreUpperCaseKeys }) as Camelized<UserResponse>;

        // identify user in Sentry
        Sentry.setUser({ id: camelized.userAccountId });

        // these are for testing granular KYC states
        // camelized.cryptoKycStatus = 'APPROVED' as any;
        // camelized.securitiesKycStatus = 'NOT_STARTED' as any;

        return camelized;
      });
    },
  });

  // blocking request - omits isLoading and other unused properties
  return {
    data,
    isError,
    refetch,
    isRefetching,
  };
};

export default useGetUser;
