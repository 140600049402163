import { create } from 'zustand';

import { GlobalStore, SpecificProduct } from '~/customTypes';

const storageKey = 'clientOrderIds';
export const createGlobalStore = () =>
  create<GlobalStore>()((set, get) => ({
    userBalancePollingTimestamp:
      typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('userBalancePollingTimestamp') || 'null') : null,

    clientOrderIds: typeof window !== 'undefined' ? JSON.parse(localStorage.getItem(storageKey) || '[]') : [],

    setUserAuthInfo: (args) => set(() => ({ userAuthInfo: { ...args } })),

    addClientOrderId: (orderId: string) => {
      const existingIds = get().clientOrderIds || [];
      const newEntry = { id: orderId, timestamp: Date.now() };
      const updatedIds = [...existingIds, newEntry];
      localStorage.setItem(storageKey, JSON.stringify(updatedIds));

      set(() => ({ clientOrderIds: updatedIds }));
    },

    removeClientOrderId: (orderId: string) => {
      const existingIds = get().clientOrderIds || [];
      const updatedIds = existingIds.filter((entry) => entry.id !== orderId);
      localStorage.setItem(storageKey, JSON.stringify(updatedIds));

      set(() => ({ clientOrderIds: updatedIds }));
    },

    setUserBalancePollingTimestamp: (timestamp: number | null) => {
      localStorage.setItem('userBalancePollingTimestamp', JSON.stringify(timestamp));
      set(() => ({ userBalancePollingTimestamp: timestamp }));
    },

    onboardingProducts: [],
    setOnboardingProducts: (products: SpecificProduct[]) => {
      set(() => ({ onboardingProducts: products }));
    },

    onboardingError: null,
    setOnboardingError: (error: string | null) => {
      set(() => ({ onboardingError: error }));
    },
  }));
