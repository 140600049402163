import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Camelized, camelizeKeys, decamelizeKeys } from 'humps';
import { jwtDecode } from 'jwt-decode';
import mixpanel from 'mixpanel-browser';

import { DbpAuthRequest, DbpTokenResponse } from '~/codegen/types';
import { IdTokenParams } from '~/customTypes';
import { useAxiosContext, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';

const usePostAuth = () => {
  const { apiClient, setHeaders } = useAxiosContext();

  const setUserAuthInfo = useGlobalStore((state) => state.setUserAuthInfo);

  const mutation = useMutation({
    mutationFn: async (body: Camelized<DbpAuthRequest>) => {
      return apiClient?.post(apiRoutes.auth(), decamelizeKeys(body)).then((r) => {
        return camelizeKeys(r.data) as Camelized<DbpTokenResponse>;
      });
    },

    onSuccess: (data) => {
      setUserAuthInfo({
        idToken: data?.idToken,
        expiresIn: data?.expiresIn,
        expiryDate: dayjs().add(Number(data?.expiresIn), 'second').unix(),
      });

      if (data?.idToken && apiClient) {
        // jwtDecode does not validate the token
        // if the token is invalid, it will throw InvalidTokenError
        // and halt the rest of the operations.
        const decodedToken: IdTokenParams = jwtDecode(data.idToken);

        setHeaders!({
          Authorization: `Bearer ${data.idToken}`,
          'fi-id': decodedToken['custom:cws-fi-id'],
          'user-account-id': decodedToken['custom:cws-fi-user-id'],
        });
      }
    },
    onError: (error) => {
      mixpanel.track('POST Auth Error', {
        Error: error.toString(),
      });
    },
  });

  return mutation;
};

export default usePostAuth;
