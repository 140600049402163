import { cloneElement, isValidElement } from 'react';

/**
 *
 * @description Like structuredClone, with additional support for React elements.
 */
const cloneDeepReact = (obj: any): any => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (isValidElement(obj)) {
    return cloneElement(obj);
  }

  if (Array.isArray(obj)) {
    return obj.map(cloneDeepReact);
  }

  const clonedObj: any = {};
  for (const key in obj) {
    clonedObj[key] = cloneDeepReact(obj[key]);
  }

  return clonedObj;
};

export { cloneDeepReact };
