'use client';

import { Box, Circle, Heading, HStack, Stack, VStack } from '@chakra-ui/react';
import { CfPrettyWrap, uiColors } from '@cryptofi/core-ui';
import { Camelized } from 'humps';

import { UserAssetAllocation } from '~/codegen/types';

type Props = {
  portfolioAllocation: Camelized<UserAssetAllocation>[];
};

const mockData = [
  {
    asset: "Credit Union's Money Market Account",
    allocationPercentage: '10',
  },
  {
    asset: 'iShares US Treasury Bond ETF (GOVT)',
    allocationPercentage: '30',
  },
  {
    asset: 'iShares Broad USD Investment Grade Corporate Bond (USIG)',
    allocationPercentage: '20',
  },
  {
    asset: 'Vanguard Tax-Exempt Bond Index Fund ETF (VTEB)',
    allocationPercentage: '10',
  },
  {
    asset: 'Vanguard High Dividend Yield Index Fund ETF (VYM)',
    allocationPercentage: '10',
  },
  {
    asset: 'iShares Core S&P 500 ETF (IVV)',
    allocationPercentage: '5',
    color: '#38BDF8',
  },
  {
    asset: 'Vanguard FTSE All World ex US ETF (VEU)',
    allocationPercentage: '5',
  },
  {
    asset: 'Vanguard Real Estate Index Fund ETF (VNQ)',
    allocationPercentage: '5',
  },
];

const PortfolioCompositionChart = ({ portfolioAllocation }: Props) => {
  const chartColors = ['#4200FF', '#EB00FF', '#B144FF', '#FF004D', '#FFA800', '#27B0E2', '#52B729', '#E1E1E1'];

  portfolioAllocation = mockData;

  const data = portfolioAllocation
    ?.map((asset, i) => {
      return {
        asset: asset.asset,
        allocation: asset.allocationPercentage,
        color: i < 8 ? chartColors[i] : uiColors.mint(), //defaults to mint once chartColors have been exhausted
      };
    })
    // eslint-disable-next-line no-restricted-syntax
    .sort((a, b) => Number(b.allocation) - Number(a.allocation));

  return (
    <Box maxW="3xl" w="100%">
      <VStack spacing="2" align="stretch">
        {data.map((item, index) => (
          <HStack key={index}>
            <Circle size=".75rem" bg={item.color} />

            <Heading fontSize="sm" w="2rem" color={uiColors.sonicSilver()} whiteSpace="none">
              {item.allocation}%
            </Heading>

            <CfPrettyWrap fontSize="sm" flex="1">
              {item.asset}
            </CfPrettyWrap>
          </HStack>
        ))}

        <Stack direction="row" rounded="full" overflow="hidden" mt="1.5rem">
          {data.map((item, index) => (
            <Box key={index} h=".25rem" rounded="lg" bg={item.color} w={`${item.allocation}%`} />
          ))}
        </Stack>
      </VStack>
    </Box>
  );
};

export default PortfolioCompositionChart;
